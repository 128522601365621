<template>
    <LoadingSpinner v-if="isLoading"/>
    <!-- Login failed message -->
    <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-4">
        <div class="flex">
        <div class="flex-shrink-0">
            <!-- Heroicon name: solid/x-circle -->
            <svg
            class="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            >
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
            />
            </svg>
        </div>
        <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">Failed to validate email.</h3>
            <div class="mt-2 text-red-700">
            <p class=" text-red-700 text-sm ">{{message}}</p>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from "../components/LoadingSpinner.vue"
import { NopService } from '../services/nopService'
export default {
    components: {
        LoadingSpinner
    },
    setup() {
        
    },
    data() {
      return {
          token: this.$route.query.token,
          guid: this.$route.query.guid,
          isLoading: true,
          showFailure: false,
          message: "",
      }
  },
  created() {
      this.isLoading = true;
      this.activateAccount();
      this.isLoading = false;
  },
  methods: {
      async activateAccount() {
          let resp = await NopService.activateAccount(this.token, this.guid);
          if (resp.data.Status == 1) {
            this.$router.push({ name: "Login", query: {guid: this.guid, isValid: 1} });
          }
          else {
            //failed
            this.showFailure = true;
            this.message = resp.Message;
          }
      }
  }
}
</script>


